@main_color: #1848A7;
@color-light-blue: #F2F7FF;
@color-black: #121212;
@color-blue: #2787F5;
@color-dark-blue: #196AC7;
@color-white: #FFFFFF;
@color-yellow: #FFE600;
@color-light-gray: #F4F4F4;
@color-gray: #DADADA;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "src/less/header";
@import "src/less/template";
@import "src/less/home";
@import "src/less/category";
@import "src/less/footer";
@import "src/less/advert";
@import "src/less/register";
@import "src/less/chat";
@import "src/less/news";
@import "src/less/favorites";
@import "src/less/user";
@import "src/less/contacts";

* {
  font-family: Montserrat;
}

.slick-track {
  display: flex;
}