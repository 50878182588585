.header-location {
  @media (max-width: 767px){
    * {
      color: transparent!important;
      font-size: 0!important;
    }
  }
}

.header-top-row {
  padding: 19px 22px 35px;
  @media (max-width: 767px){
    padding: 19px 10px 25px;
  }
  @media (max-width: 1023px){
    gap: 20px 0!important;
  }

  .ant-btn {
    &.blank,
    &.blank:hover {
      min-height: auto;
      padding: 0!important;
      background: transparent;
      color: @color-black;
      font-weight: 500;
      border: none;
      box-shadow: none;
      transition: .3s ease;

      svg {
        path {
          stroke: unset;
        }
        path:first-child {
          fill: @color-blue;
          transition: .3s ease;
        }
        path:last-child {
          fill: @color-black;
          transition: .3s ease;
        }
      }
    }
    &.blank:hover {
      color: @color-blue;

      svg {
        path:first-child {
          fill: @color-black;
        }
        path:last-child {
          fill: @color-blue;
        }
      }
    }
  }

  .page-links {
    display: flex;
    align-items: center;
    gap: 27px;
    @media (max-width: 767px){
      justify-content: flex-end;
      gap: 15px;
    }

    a {
      color: @color-black;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      transition: .3s ease;
      &:hover {
        color: @color-blue;
      }
      &.colored {
        color: @color-blue;
        &:hover {
          color: @color-black;
        }
      }
    }
  }

  .header-buttons {
    justify-content: flex-end;
    gap: 27px;
    @media (max-width: 767px){
      justify-content: space-between;
      flex-flow: row wrap;
      gap: 15px;
    }

    a {
      position: relative;
      &.button-blue {
        padding: 7px 18px!important;
        background: @color-blue;
        color: @color-white;
        border-radius: 15px;
        overflow: hidden;
        &::before {
          content: '';
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 100%;
          margin: -15px 0 0 1px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: @color-dark-blue;
          transform-origin: 100% 50%;
          transform: scale3d(1, 2, 1);
          transition: transform .3s, opacity .3s;
          transition-timing-function: cubic-bezier(0.7,0,0.9,1);
        }
        &:hover::before {
          transform: scale3d(9, 9, 1);
        }

        span {
          position: relative;
          display: inline-block;
          z-index: 2;
        }
      }

      .header-name {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%,5px);
        width: 500%;
        color: #ccc;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
      }

      svg {
        path:first-child {
          fill: @color-blue;
          transition: .3s ease;
        }
        path:last-child {
          fill: @color-black;
          transition: .3s ease;
        }
        &:hover {
          path:first-child {
            fill: @color-black;
          }
          path:last-child {
            fill: @color-blue;
          }
        }
      }
    }
  }
}

.header-bottom-row {
  margin-bottom: 37px;
  padding: 19px;
  background: @color-white;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  @media (max-width: 767px){
    gap: 22px 0!important;
  }

  .header-logo {
    img {
      width: 95%;
      @media (max-width: 767px){
        width: 100%;
      }
    }
  }

  .header-search {
    display: flex;
    border: 2px solid @color-blue;
    border-radius: 20px;
    overflow: hidden;
    @media (max-width: 767px){
      flex-flow: column;
      gap: 5px;
    }

    .ant-select {
      flex: 0 0 20%;
      min-width: 180px;
      box-shadow: unset!important;
      border-right: 1px solid @color-gray;
      @media (max-width: 767px){
        min-width: unset;
        flex: unset;
      }

      .ant-select-selector {
        height: 40px;
        padding: 0 22px;
        border: none!important;
        border-radius: 20px;
        box-shadow: unset!important;

        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          color: @color-black;
          font-size: 14px;
          font-weight: 500;
          line-height: 40px;
        }
      }
    }

    .ant-input {
      height: 40px;
      padding: 0 15px 0 15px;
      color: @color-black;
      font-size: 14px;
      font-weight: 500;
      line-height: 40px;
      border: none;
      border-right: 1px solid @color-gray;
      border-radius: unset;
      box-shadow: unset;
      @media (max-width: 767px){
        padding: 0 22px;
      }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
      top: 50%;
      left: 20px;
      transform: translateY(-70%);
    }

    .ant-btn {
      flex: 1 0 100px;
      display: block;
      text-align: center;
      &.button-blue {
        position: relative;
        height: 40px;
        margin: 0;
        background: @color-blue;
        color: @color-white;
        line-height: 40px;
        overflow: hidden;
        border-radius: unset;
        @media (max-width: 767px){
          flex: unset;
          width: 100%;
        }

        &::before {
          content: '';
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 100%;
          margin: -15px 0 0 1px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: @color-dark-blue;
          transform-origin: 100% 50%;
          transform: scale3d(1, 2, 1);
          transition: transform .3s, opacity .3s;
          transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
        }

        &:hover::before {
          transform: scale3d(9, 9, 1);
        }

        span {
          position: relative;
          display: inline-block;
          z-index: 2;
        }
      }
    }
  }
}