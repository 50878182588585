body {
  font-family: 'Montserrat', sans-serif;
  color: #232935;
  letter-spacing: 0;
  overflow-x: hidden;

  & > iframe {
    display: none;
  }
}
.template {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
  min-height: calc(100vh - 130px);
  @media (max-width: 1400px) {
    padding: 0 15px;
    overflow: hidden;
  }
  &.footer-t {
    padding: 0;
    min-height: auto;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1400px){
      padding: 0;
    }
  }
  & > .ant-col {
    position: static!important;
  }

  .ant-btn {
    width: fit-content;
    min-height: 32px;
    padding: 5px 15px !important;
    display: flex;
    align-items: center;
    background: @main_color;
    color: @color-light-blue;
    font-size: 14px;
    font-weight: 500;
    line-height: 1!important;
    border: none;
    border-radius: 8px;
    transition: .3s ease;
    &:hover {
      background: @color-light-blue;
      color: @main_color;
      transition: .3s ease;

      svg path {
        stroke: @main_color;
        transition: .3s ease;
      }
    }

    &.ant-btn-dangerous {
      color: #fff;
      border-color: #ff4d4f;
      background: #ff4d4f;
      &:hover {
        border-color: #ff7875;
        background: #ff7875;
      }
    }

    &.white {
      background: #fff;
      color: #1848A7;
      border: 1px solid #1848A7;
      &:hover,
      &.active {
        background: #F2F7FF;
        color: #1848A7;
      }
    }

    &.rightSided {
      padding: 10px 22px;
      svg {
        margin-right: 0;
        margin-left: 10px;

        path {
          stroke: transparent;
          fill: @color-light-blue;
        }
      }
      &:hover {
        svg {
          path {
            stroke: transparent;
            fill: @main_color;
          }
        }
      }
    }

    svg {
      margin-right: 10px;
      path {
        stroke: @color-light-blue;
        transition: .3s ease;
      }
    }

    &.inverted {
      background: @color-light-blue;
      color: @main_color;
      &:hover {
        background: @main_color;
        color: @color-light-blue;
        transition: .3s ease;

        svg path {
          stroke: @color-light-blue;
          transition: .3s ease;
        }
      }

      svg {
        margin-right: 10px;
        path {
          stroke: @main_color;
          transition: .3s ease;
        }
      }
    }

    &.noText {
      svg {
        margin-right: 0;
      }
    }

    &.cleared {
      padding: 5px;
      background: transparent;
      color: transparent;
      font-size: 0;
      &:hover {
        background: transparent;
        color: transparent;

        svg path {
          stroke: @main_color;
          opacity: .75;
        }
      }
    }

    &.noFontSize {
      @media(min-width:1023px) and (max-width: 1600px){
        font-size: 0;
        svg {
          margin-right: 0;
        }
      }
    }

    @media(min-width: 1100px) and (max-width: 1300px){
      margin-bottom: 20px;
    }

    &.semi-transparent {
      max-height: 33px;
      margin: 0 0 20px 0;
      background: rgba(242, 247, 255, 0.1);
      color: #FFF;
      &:not(:last-child) {
        margin-right: 10px;
      }

      svg {
        path {
          stroke: #FFF;
          transition: .3s ease;
        }
      }
      &:hover {
        background: @color-light-blue;
        color: @main_color;

        svg {
          path {
            stroke: @main_color;
          }
        }
      }
    }
  }

  .template-logo {
    img {
      max-height: 54px;
      @media (max-width: 991px) {
        max-height: 50px;
      }
      @media (max-width: 767px) {
        max-height: 42px;
      }
      @media (max-width: 575px) {
        max-height: 32px;
      }
    }
  }

  .template-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1199px) {
      margin-bottom: 20px;
      &.forCreate {
        margin-bottom: 0;
      }
    }
    @media (min-width: 992px) {
      &.notLogin {
        margin-bottom: 0;
      }
    }
  }

  .template-center {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: @main_color;
    font-size: 14px;
    font-weight: 500;
    line-height: 0;
    @media (max-width: 1199px) {
      padding-left: 20px;
      margin-bottom: 20px;
      justify-content: space-between;
    }
  }

  .template-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row wrap;

    .user-id {
      margin: 0 20px;
      color: @main_color;
      font-size: 14px;
      font-weight: 500;
      line-height: 0;
    }

    @media (max-width: 575px) {
      justify-content: flex-start;
      margin-bottom: -20px;
    }
    @media (max-width: 1199px) {
      justify-content: center;
      &.forCreate {
        justify-content: flex-end;
      }

      .ant-btn,
      .user-id {
        margin-bottom: 20px;
      }
    }
    @media (min-width: 991px) {
      &.notLogin {
        justify-content: flex-end;
      }
    }

    .ant-btn {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .icons {
      display: flex;
      align-items: center;

      .ant-btn {
        margin-right: 20px;

        svg {
          margin-right: 0;
        }
      }
    }
  }

  .home-block-head {
    margin: 38px 0 27px;

    &.less {
      margin: 38px 0 0;
    }

    h2 {
      margin-bottom: 0;
      font-size: 28px;
      font-weight: 600;
      line-height: 46px;
      @media(max-width: 575px){
        margin-bottom: 15px;
        line-height: 38px;
      }
    }

    h3 {
      margin-bottom: 25px;
      font-size: 20px;
      font-weight: 500;
      line-height: 46px;
      @media(max-width: 575px){
        margin-bottom: 15px;
        line-height: 38px;
      }
    }
  }
}
.template-modal {
  h3 {
    opacity: 0.85;
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #111111;
    margin-bottom: 25px;
  }
  .search {
    width: 100%;
    max-width: 328px;
  }
  .alphabet {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-flow: row wrap;
    @media(max-width: 767px){
      margin-bottom: 15px;
      span {
        margin-bottom: 10px;
      }
    }

    span {
      width: 32px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: #575757;
      text-transform: uppercase;
      opacity: 0.38;
      @media(max-width: 991px){
        width: 20px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
  .item {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.56;
    letter-spacing: normal;
    color: #051f30;
    opacity: 0.63;

    &:hover {
      cursor: pointer;
    }
  }
}
.underline {
  span{
    text-decoration: underline;
  }
}
.breadcrumbs {
	@media(max-width: 991px) {
		margin-top: 30px;
	}
}
.sitemap {
  a {
    display: inline-block;
    margin-left: 10px;
    color: #000;
    transition: .3s ease;
    &:hover {
      color: #8e8ea6;
    }
  }
}