.home {

  .home-filters {
    margin-top: -30px;
    background: #fff;
    border: 1px solid #F2F7FF;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(25, 72, 166, 0.05);
    overflow: hidden;

    .fillerCol {
      @media (max-width: 767px) {
        display: none;
      }
    }

    .filter-item {
      border-right: 1px solid #EFEFF9;
      border-bottom: 1px solid #EFEFF9;

      &.border-top {
        border-top: 1px solid #EFEFF9;
      }

      &:last-child {
        flex-grow: 1;
      }

      .ant-select {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;

        .ant-select-selector {
          height: auto;
          padding: 15px 20px;
          border: none;
          border-radius: 0;
          box-shadow: none !important;
        }
      }

      .ant-select-multiple .ant-select-selection-placeholder {
        left: 30px;
        right: 30px;
      }

      &.filter-inputs {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        color: #94A4B4;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        @media (max-width: 575px) {
          flex-flow: row wrap;
        }

        & > *:not(:last-child) {
          margin-right: 30px;
        }
        & > * {
          @media (max-width: 575px) {
            width: 100%;
            margin: 0 0 15px 0;
          }
        }

        .ant-input-number {
          width: auto;
          border: none;
          border-radius: 0;
          box-shadow: none !important;

          .ant-input-number-handler-wrap {
            display: none;
          }

          .ant-input-number-input-wrap {
            min-width: 120px;
          }
        }

        input {
          padding: 10px 0;
          font-size: 16px;
          font-weight: 500;
          line-height: 1;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid @main_color;
          box-shadow: none !important;
        }

        & > div {
          display: flex;
          align-items: center;

          span {
            margin-right: 20px;
          }
        }
      }
    }

    .filter-buttons {
      padding-right: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: #fff;
      @media (max-width: 1199px) {
        padding: 15px 20px;
        justify-content: center;
      }

      & > *:not(:last-child) {
        margin-right: 20px;
      }

      .ant-btn {
        padding: 10px 22px;

        &.rightSided {
          svg {
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .home-results-head {
    .results-filter {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .ant-radio-group {
        display: flex;

        .ant-radio-button-wrapper {
          height: auto;
          padding: 5px 15px;
          background: @color-light-blue;
          color: @main_color;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          border: none;
          border-radius: 8px!important;
          &:not(:last-child) {
            margin-right: 10px;
          }
          &:hover,
          &.ant-radio-button-wrapper-checked {
            background: @main_color;
            color: @color-light-blue;
            transition: .3s ease;

            svg path {
              stroke: @color-light-blue;
              transition: .3s ease;
            }
          }
          &:not(:first-child)::before {
            content: unset;
          }

          svg {
            margin-right: 10px;
            path {
              stroke: @main_color;
              transition: .3s ease;
            }
          }

          span:last-child {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .home-block {
    padding-bottom: 50px;
    border-bottom: 1px solid #EFEFF9;
  }

  .home-how {
    margin: 37px -10px 26px!important;
    padding-bottom: 50px;

    .home-block-head {
      margin: 28px 0 17px;
    }

    .how-single-item {
      height: 100%;
      padding: 20px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 10px 30px rgba(25, 72, 166, 0.05);

      .item-head {
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .step-circle {
          width: 5px;
          height: 5px;
          background: @color-light-blue;
          font-size: 0;
        }

        .arrow-right-blue {
          position: relative;
          width: 100%;
          max-width: calc(100% - 70px - 20px);
          height: 2px;
          background: #1948A6;
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 10px;
            height: 10px;
            transform: translateY(-50%) rotate(45deg);
            border-top: 2px solid #1948A6;
            border-right: 2px solid #1948A6;
          }
        }
      }

      .item-content {
        font-size: 16px;
        line-height: 28px;
      }
    }

    &.blue {
      .how-single-item {
        background: @color-light-blue;;
      }
    }

    .how-button {
      margin-top: 30px;

      .ant-btn {
        margin: 0 auto;
        padding: 10px 22px;
      }
    }
  }

  .single-document {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    background: #F7FAFF;
    border-radius: 10px;

    .document-heading {
      flex: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #1948A6;
      font-size: 16px;
      font-weight: 600;
      line-height: 46px;
    }
  }

  .single-question {
    padding: 7px 20px;
    background: #F2F7FF;
    border-radius: 10px;
    cursor: pointer;
    &.expanded {
      .question-content {
        max-height: 1000px;
        margin-bottom: 18px;
        opacity: 1;
        visibility: visible;
        transition: .3s ease;
      }

      svg {
        transform: rotate(180deg);
        transition: .3s ease;
      }
    }

    svg {
      transition: .3s ease;
    }

    .question-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 600;
      line-height: 46px;
    }

    .question-content {
      max-height: 0;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 24px;
      opacity: 0;
      visibility: hidden;
      transition: .3s ease;
    }
  }
}

.single-request {
  margin-bottom: 10px;
  padding: 20px;
  background: #fff;
  border: 1px solid #EFEFF9;
  border-radius: 10px;
  transition: .3s ease;
  &:not(.selected):hover {
    background: @color-light-blue;
    border-color: @color-light-blue;
    transition: .3s ease;
  }
  &.red {
    background: #fde9ef;
  }
  &.selected {
    background: rgba(255, 192, 0, .1);
    border-color: #FFC000;
    transition: .3s ease;

    svg {
      circle {
        fill: #FFC000;
      }

      path {
        fill: #FFC000;
        stroke: #000;
      }
    }

    .req-first-line {
      .req-name,
      .req-price {
        color: #D6A000;
      }
    }
  }

  .req-first-line {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    @media(max-width: 767px){
      padding-left: 75px;
      flex-flow: column!important;

      .req-name {
        margin-bottom: 15px;
      }
    }

    .req-name {
      color: @main_color;
    }

    .req-price {
      color: #232935;
    }
  }

  .req-text {
    margin-bottom: 15px;
    color: #232935;
    font-size: 14px;
    line-height: 1.3;
  }

  .req-address {
    margin-right: 20px;
    color: #232935;
    font-size: 14px;
    @media(max-width: 767px){
      width: 100%;
      margin-bottom: 10px;
    }

    svg {
      margin-right: 10px;
    }

    span.metro {
      position: relative;
      margin-right: 22px;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -11px;
        transform: translate(50%,-50%);
        width: 4px;
        height: 4px;
        background: #EFEFF9;
      }
    }
  }

  .req-address span.address,
  .req-date {
    color: #94A4B4;
    font-size: 14px;
    line-height: 1;
  }

  .req-date {
    @media(max-width: 767px){
      width: 100%;
    }
  }

  .req-offer {
    margin-top: 15px;
    color: #232935;
    font-size: 16px;
    font-weight: 600;
    &.active {
      .ant-btn {
        background: #FFC000;
        color: #232935;
        &:hover {
          background: #fdf1b3;
        }
      }
    }
  }
  .req-error {
    margin-top: 15px;
    color: #232935;
    font-size: 16px;
    font-weight: 600;
  }
}


.result-item {
  padding: 16px 41px 16px 23px;
  border-radius: 7px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
	
	@media(max-width: 575px){
		padding: 15px;	
	}
  .circle-item {
    width: 48px;
    height: 48px;
    position: relative;
    background: #1848a7;
    border-radius: 500px;
    text-align: center;

    span {
      position: absolute;
      top: 12px;
      right: 0;
      left: 0;
      margin: 0 auto;
      color: white;
      font-size: 16px;
      font-weight: bold;
    }
  }
	.item-type,
	.title-item {
		@media(max-width: 575px){
			margin-bottom: 20px;
		}
	}
  .title-item {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #575757;
  }
  .item-like {
    text-align: right;

    button {
      padding: 0;
      border: none;
      background: transparent;
      outline: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .created-item {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #575757;
    line-height: 1;
  }
}

.video-modal {
  width: 100vw;
  max-width: 1200px;

  .video-embed {
    position: relative;
    height: 0;
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.in-digits {
  padding: 55px 40px;
  background: #1948A6;
  color: #FFF;
  border-radius: 10px;

  .in-digits-heading {
    margin-bottom: 15px;
    font-size: 38px;
    font-weight: 600;
    line-height: 1.3;
  }

  .in-digits-description {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
  }
}

.contact-form-container {
  position: relative;
  //@media(min-width: 1024px){
  //  margin-top: 100px!important;
  //  margin-bottom: 100px!important;
  //}

  .form-background {
    position: absolute;
    right: -20px;
    top: -80px;
    width: 100%;
    max-width: 558px;
    height: calc(100% + 200px);
    max-height: 558px;
    z-index: -1;
    @media(max-width: 1023px){
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.contact-form {
  border: 1px solid #EFEFF9;
  border-radius: 10px;
  box-shadow: 0 10px 40px rgba(148, 164, 180, 0.1);
  overflow: hidden;
}

.form-row {
  display: flex;
  &.form-footer {
    position: relative;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
    & > *:not(:last-child) {
      margin-right: 20px;
    }
    @media(max-width: 767px){
      flex-flow: row wrap;
    }

    .agreement {
      color: #94A4B4;
      font-size: 12px;
      line-height: 20px;
      @media(max-width: 767px){
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .upload-files .ant-upload {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 5px;
      }
    }

    .ant-upload-list {
      position: absolute;
      top: -60px;
      left: 20px;
      display: flex;

      .ant-upload-text-icon {
        display: none;
      }

      .ant-upload-list-item-info > span {
        display: flex;
        align-items: center;
      }

      .ant-upload-list-item {
        margin-right: 20px;
        &:hover .ant-upload-list-item-info {
          background: transparent;
        }
      }

      .ant-upload-list-item-name {
        padding-left: 0;
        padding-right: 10px;
        color: #94A4B4;
        font-size: 13px;
        line-height: 1.2;
      }

      .ant-upload-list-item-card-actions-btn {
        opacity: 1;
      }

      .ant-btn {
        padding: 0;
        background: transparent;
        line-height: 1;

        svg {
          margin-right: 0;
        }
      }

      .ant-upload-list-item-undefined {
        .ant-upload-list-item-name {
          color: red;
        }
      }
    }
  }

  .styled-input {
    position: relative;
    flex: 0 0 calc(100% / 3);
    &.w100 {
      flex: 0 0 100%;
    }
    &:last-child {
      input,
      textarea {
        border-color: transparent;
      }
    }

    label {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 40px);
      padding: 0;
      background: #fff;
      color: #94A4B4;
      font-size: 14px;
      line-height: 1.8;
      transition: .2s ease
    }

    textarea + label {
      top: 27px;
    }

    input,
    textarea {
      width: 100%;
      padding: 20px 20px 10px;
      border-color: transparent;
      border-right: 1px solid #EFEFF9;
      border-radius: 0;
      resize: none;
      &::placeholder {
        font-size: 0
      }
      &:focus,
      &:hover {
        border-color: transparent;
        border-right: 1px solid #EFEFF9;
        box-shadow: none;
      }
      &:focus + label,
      &:not(:placeholder-shown) + label {
        top: 13px;
        font-size: 11px;
        transition: .2s ease
      }
    }

    textarea {
      height: 150px;
      padding-bottom: 40px;
      border-top: 1px solid #EFEFF9!important;
      &:focus,
      &:hover {
        border-top: 1px solid #EFEFF9!important;
      }
    }
  }
}

@keyframes blink-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.reviews-slider {
  position: relative;
  width: calc(100% + 20px);

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: transparent;
    color: transparent;
    font-size: 0;
    line-height: 0;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 3;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background: #1948A6;
      transition: .3s ease;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 10px;
      height: 10px;
      border-top: 2px solid #1948A6;
      border-right: 2px solid #1948A6;
      transition: .3s ease;
    }
    &.slick-disabled {
      &::before {
        background: transparent;
        transition: .3s ease;
      }
      &::after {
        border-color: transparent;
        transition: .3s ease;
      }
    }

    &.slick-prev {
      left: -40px;
      &::after {
        left: 0;
        transform: translateY(-50%) rotate(-135deg);
      }
      @media(max-width: 479px){
        left: -10px;
      }
    }
    &.slick-next {
      right: -20px;
      &::after {
        right: 0;
        transform: translateY(-50%) rotate(45deg);
      }
      @media(max-width: 479px){
        right: 10px;
      }
    }
  }

  .single-slide {
    width: calc(100% - 20px)!important;
    padding: 20px;
    background: #fff;
    border: 1px solid #EFEFF9;
    border-radius: 10px;

    .slide-header {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      .image {
        width: 70px;
        height: 70px;
        margin-right: 20px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .header-name {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
      }

      .header-subname {
        color: #94A4B4;
        font-size: 14px;
        line-height: 1;
      }
    }

    .slide-middle {
      margin-bottom: 20px;

      .middle-row {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .slide-text {
      font-size: 16px;
      line-height: 24px;
    }

    .slide-more {
      width: fit-content;
      margin-top: 10px;
      color: #1948A6;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.home-heading {
  width: fit-content;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

.section-header {
  margin-bottom: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px){
    flex-flow: column;
    gap: 20px!important;
  }

  .section-selector {
    display: flex;

    label {
      height: 40px;
      padding: 0 14px;
      display: flex;
      align-items: center;
      color: @color-black;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      border: none;
      border-radius: 20px;
      outline: none!important;
      box-shadow: unset!important;
      transition: .3s ease;
      &:hover {
        color: @color-blue;
      }
      &:not(:last-child) {
        margin-right: 5px;
      }
      &::before {
        content: unset;
      }
      &.ant-radio-button-wrapper-checked {
        background: @color-light-gray;
      }

      span:last-child {
        display: flex;
        align-items: center;
      }

      svg {
        margin-right: 12px;
      }
    }
  }
}

.home-slider {
  margin: 0 -10px 75px -10px;
  &.main-slider {
    .home-slide {
      height: 240px;
    }
  }

  .slick-list {
    overflow: visible!important;

    .slick-slide {
      pointer-events: none;
      opacity: .6;
      transition: .5s ease;
      &.slick-active {
        pointer-events: all;
        opacity: 1;

        .home-slide::before {
          opacity: .3;
        }

        .slide-meta {
          opacity: 1;
        }
      }

      .home-slide::before {
        opacity: 0;
      }

      .slide-meta {
        opacity: 0;
      }
    }
  }

  .home-slide {
    position: relative;
    margin: 0 10px;
    max-height: 240px;
    display: block;
    border-radius: 12px;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: @color-black;
      transition: .5s ease;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .slide-meta {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 21px 35px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      color: @color-white;
      transition: .5s ease;

      .meta-header {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;

        .meta-timer {
          width: fit-content;
          margin-top: 8px;
          padding: 5px 10px 5px 5px;
          display: flex;
          align-items: center;
          background: @color-white;
          color: @color-black;
          font-weight: 600;
          border-radius: 15px;

          span {
            margin: 0 3px;
            color: @color-blue;
          }

          svg {
            margin-right: 8px;
          }
        }
      }

      .meta-footer {

        &-heading {
          font-size: 24px;
          font-weight: 600;
        }

        &-description {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }

  &.categories-slider {
    margin-bottom: 56px;

    .home-slide {
      max-height: unset;
      border-radius: unset;
      &::before {
        content: unset;
      }

      .slide-image {
        position: relative;
        width: 100%;
        padding-top: 100%;
        border-radius: 12px;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .slide-heading {
        margin-top: 15px;
        color: @color-black;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 36px;
    background: rgba(18, 18, 18, .4);
    color: transparent;
    font-size: 0;
    line-height: 0;
    outline: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 3;
    transition: .3s ease;
    &:hover {
      background: @color-blue;
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 40%;
      width: 8px;
      height: 8px;
      border-top: 1px solid @color-white;
      border-left: 1px solid @color-white;
    }

    &.slick-prev {
      left: 0;
      transform: translate(0,-50%);
      &::before {
        top: 40%;
        transform: rotate(315deg);
      }
    }

    &.slick-next {
      right: 0;
      transform: translate(0,-50%);
      &::before {
        top: 40%;
        transform: rotate(135deg);
      }
    }
  }

  &.categories-slider {
    .slick-arrow {
      top: 45%;
    }
  }
}

.side-slider {
  margin-top: 10px;
  margin-bottom: 24px;

  img {
    width: 100%;
  }
}

.vk-link {
  display: block;
  margin-top: 10px;
  margin-bottom: 24px;

  img {
    width: 100%;
  }
}

.vk-link-text {
  width: fit-content;
  display: flex;
  align-items: center;
  color: @color-black;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  transition: .3s ease;
  &:hover {
    color: @color-blue;
  }

  img {
    margin-right: 15px;
  }
}

.select-category-view {
  color: @color-blue;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.categories-list {
  @gap: 15px;
  margin-bottom: 56px;
  display: flex;
  flex-flow: row wrap;
  gap: @gap;

  .item {
    flex: 0 0 calc(25% - @gap);
    @media (max-width: 767px){
      flex: 0 0 calc(50% - @gap);
    }

    a {
      width: fit-content;
      color: @color-blue;
      font-size: 16px;
      font-weight: 500;
      transition: .3s ease;
      &:hover {
        color: @black;
      }
    }
  }
}

.home-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  .nadvert-badge {
    padding: 8px 12px;
    background: #fff;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    cursor: pointer;
    z-index: 2;
    transition: .3s ease;
    &:hover {
      opacity: .9;
    }
  }
}

