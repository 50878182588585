.user {
	@media(max-width: 991px){
		margin-top: 30px;
	}
  h1 {
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #111111;
		@media(max-width: 991px){
			margin-bottom: 30px;	
		}
  }
  .yourID {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2f2f2f;
    margin-bottom: 52px;

    span {
      font-weight: bold;
      color: #1848a7;
    }
		@media(max-width: 991px){
			display: none;
		}
  }
  .inputs {
    margin-top: 20px;
    padding-right: 23px;

    .label {
      font-size: 14px;
      font-weight: 500;
      color: #575757;
      margin-bottom: 5px;
      display: block;
    }
    .require {
      font-size: 14px;
      font-weight: 500;
      color: #8e8ea6;
      margin-top: 5px;
      display: block;
    }
		@media(max-width: 575px){
			padding: 0;
		}
		@media(max-width: 991px){
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
  }
  h3 {
    margin-top: 43px;
    margin-bottom: 23px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #111111;
  }
  .save {
    margin-top: 23px;
  }
  .pay {
    padding: 3px 8px 4px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;

    &.no {
      background-color: #fde9ef;
      color: #111111;
    }
    &.yes {
      background-color: #64a54d;
      color: #ffffff;
    }
  }
  .description {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #111111;
    margin-top: 15px;
    width: 100%;
    max-width: 400px;
  }
}