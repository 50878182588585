.category-heading {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.category-subheading {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.category-filters {
  margin-top: 10px;
  padding: 22px;
  background: @color-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 40px;

  & > *:not(:last-child) {
    margin-bottom: 19px;
  }

  .ant-select {
    width: 100%;
    box-shadow: unset!important;

    .ant-select-selector {
      height: 36px;
      padding: 0 22px;
      border: 1px solid @color-gray!important;
      border-radius: 20px;
      box-shadow: unset!important;

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        color: @color-black;
        font-size: 14px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }
}

.ant-select-dropdown {
  padding: 14px;
  background: @color-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .ant-select-item {
    min-height: 36px;
    margin-right: 15px;
    padding: 5px 10px;
    color: @color-black;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    border-radius: 14px;
  }

  .ant-select-item-option {
    align-items: center;

    &-selected:not(.ant-select-item-option-disabled) {
      background-color: @color-light-gray!important;
    }
  }
}

.rc-virtual-list-scrollbar {
  width: 5px!important;

  .rc-virtual-list-scrollbar-thumb {
    background: @color-light-gray!important;
    border-radius: 3px!important;
  }
}


.category-nadvert-image {
  margin-top: 20px;
}