.contacts {
  h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
  }
  p {
    margin-bottom: 30px;
    font-size: 16px;
  }
  a {
    color: #000;
    transition: .3s ease;
    &:hover {
      color: #5c7bb8;
    }
  }
}