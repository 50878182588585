footer {
  margin-top: 80px;
  padding: 28px 0;
  background: @color-light-gray;

  .template {
    min-height: unset;
    @media (max-width: 767px){
      gap: 25px!important;
    }

    & > * {
      display: flex;
      align-items: center;
    }
  }

  a {
    color: @color-black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    transition: .3s ease;

    span {
      color: @color-blue;
      transition: .3s ease;
    }
    &:hover {
      color: @color-blue;

      span {
        color: @color-black;
      }
    }
  }

  .footer-logo {
    justify-content: center;
    @media (max-width: 767px){
      justify-content: flex-start;
    }
  }

  .footer-credits {
    justify-content: flex-end;
    @media (max-width: 767px){
      justify-content: flex-start;
    }
  }

  img {
    max-width: 220px;
  }
}
.footer-copyright {
  padding: 10px 0;
  background: @color-black;
  color: @color-white;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
}