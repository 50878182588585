.advert {

  .right {
    h2 {
      margin: 0 15px 0 0;
      @media(max-width: 575px){
        margin-bottom: 8px;
      }
    }

    @media(max-width: 479px){
      margin: 0 0 30px;
    }
    @media(min-width: 480px) and (max-width: 991px){
      margin: 30px 0;
    }

    .ant-btn {
      margin-right: 8px;
      @media(max-width: 575px){
        margin-bottom: 8px;
      }
    }
  }
  h2 {
    font-weight: 600;
    margin-bottom: 15px;
  }
  .cardHead {
    margin-bottom: 30px;

    p {
      margin-bottom: 0;
      color: #7e7e7e;
      font-size: 12px;
    }
  }
  h3 {
    margin-bottom: 15px;
    font-weight: 600;
  }
  .advDesc {
    p {
      margin-bottom: 30px;
      span {
        font-weight: 600;
      }
    }
    &.contactform {
      margin-top: 30px;

      textarea {
        margin-bottom: 15px;
      }
    }
  }
  label {
    display: block;
  }
  .payed-block {
    display: block;
    background: #FBB63E;
    width: 100%;
    padding: 22px 30px;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;

    p {
      font-size: 14px;
      font-weight: 500;
      color: white;
      line-height: 1.2;
    }
    a {
      font-size: 14px;
      font-weight: 500;
      color: white;
      line-height: 1;
      text-decoration: underline;
    }
  }
}
.title-adverts {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111111;
  margin: 34px 0 26px;
}
.advertList {
  margin-top: 30px;

  .element {
    border-radius: 7px;
    background-color: #f5f5f5;
    padding: 20px 33px;
    margin-bottom: 15px;

    &.red {
      background-color: #fde9ef !important;
    }
    p {
      margin: 0;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #575757;
    }
    .price {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: right;
      color: #1848a7;
      text-decoration: underline;
      @media(max-width: 479px){
        margin-top: 15px;
        text-align: left;
      }
    }
    .right {
      text-align: right;

      .circle {
        border-radius: 500px;
        width: 36px;
        height: 36px;
        position: relative;
        display: inline-block;
        opacity: 0.51;
        background-color: #5c7bb8;

        p {
          color: white;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          position: absolute;
          left: 0;
          right: 0;
          top: 8px;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }
}
.error {
  background: #fde9ef;
  padding: 29px 83px 13px 40px;
  border-radius: 7px;

  .title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #111111;
  }
  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #111111;
  }
}

.myAdvertsHead {
  @media(max-width: 991px){
    margin-top: 30px;
  }
  h1 {
    font-size: 24px;
    font-weight: 800;
    line-height: 50px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
  }
}

.add-cell {
  @media (max-width: 767px){
    text-align: left!important;
  }
}

.boldtext {
  font-weight: 700;
}

.nadverts-list {
  margin: 0 -10px;
  gap: 0 25px!important;
  @media (max-width: 767px){
    gap: 15px!important;
  }


  .ant-col {
    flex: 0 0 20%;
    @media (max-width: 767px){
      flex: 0 0 calc(50% - (15px / 2));
    }
  }
  &.four-row {
    .ant-col {
      flex: 0 0 25%;
      @media (max-width: 767px){
        flex: 0 0 calc(50% - (15px / 2));
      }
    }
  }
}

.single-nadvert {
  display: block;
  padding: 10px;
  color: @color-black!important;
  border-radius: 16px;
  &:hover {
    .nadvert-heading {
      color: @color-blue!important;
    }
  }
  &.colored {
    background: @color-yellow;

    .nadvert-price {
      padding: 0 8px;
      background: @color-white;
    }
  }

  .nadvert-image {
    position: relative;
    width: 100%;
    padding-top: 100%;
    margin-bottom: 15px;
    border-radius: 12px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .nadvert-badge {
      position: absolute;
      bottom: 5px;
      left: 5px;
      padding: 5px 8px;
      background: #fff;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      border-radius: 10px;
      z-index: 2;
    }
  }

  .nadvert-meta {
    & > *:not(:last-child) {
      margin-bottom: 8px;
    }

    .nadvert-heading {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      transition: .3s ease;
    }

    .nadvert-price {
      width: fit-content;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.3;
      border-radius: 20px;
    }

    .nadvert-footer {
      display: flex;
      flex-flow: column;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;

      .nadvert-location {
        margin-bottom: 5px;
        opacity: .6;
      }

      .nadvert-date {
        text-align: right;
        opacity: .4;
      }
    }
  }
}

.objects-map {
  width: 100%;
  height: 570px;
  margin-top: 10px;
  background: #F0F7FE;
  border-radius: 16px;
  overflow: hidden;
}
.objects-list-map-container {
  position: absolute;
  top: 27px;
  left: 27px;
  bottom: 27px;
  width: 100%;
  max-width: 227px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 2;

  .objects-list-show {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 6px);
    padding: 5px 10px;
    background: @color-white;
    color: @color-blue;
    font-weight: 600;
    border-radius: 20px;
    cursor: pointer;
    z-index: 3;
  }

  .objects-list-map  {
    width: 100%;
    height: 100%;
    background: @color-white;
    padding: 29px 0 9px 0;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease;
    &.visible {
      opacity: 1;
      visibility: visible;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: @color-light-gray;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
      background: @color-white;
    }
  }
}

.object-page {
  .section-header {
    @media (max-width: 767px){
      flex-flow: row;
    }
  }

  .object-buttons {
    justify-content: flex-end;
    @media (max-width: 767px){
      justify-content: flex-start;
    }

    .object-button {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 9px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: .3s ease;
      & * {
        transition: .3s ease;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
      &:hover {
        background: @color-blue;

        svg * {
          stroke: @color-white;
          fill: @color-white;
        }
      }

      .filled path{
        fill: #FF589C;
      }
    }
  }

  .object-prices {
    justify-content: flex-end;
    align-items: flex-end;

    .object-price {
      color: @color-black;
      font-size: 28px;
      font-weight: 600;
      line-height: 1;
    }

    .object-price-square {
      margin-left: 35px;
      color: @color-gray;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
    }
  }

  .object-images {
    border-radius: 20px;
    overflow: hidden;

    .image-gallery-thumbnails-wrapper.left {
      width: 20%;
      margin: 0;

      .image-gallery-thumbnail {
        width: 100%;
        border: 1px solid transparent;
        border-radius: 0;
        cursor: pointer;
        overflow: hidden;
        transition: .3s ease;
        &.active,
        &:hover {
          border-color: @color-light-gray;
          transition: .3s ease;
        }

        .image-gallery-thumbnail-inner {
          padding-top: 100%;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .image-gallery-slide-wrapper.left {
      width: 80%;

      .image-container {
        width: 100%;
        padding-top: 100%;
        overflow: hidden;
        cursor: pointer;

        .image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transition: transform .1s ease;
        }
      }
    }
  }

  .object-block {
    margin-bottom: 40px;

    .object-contacts {
      margin-bottom: 42px;
      display: flex;
      justify-content: space-between;

      .object-button {
        position: relative;
        width: calc(50% - 6px);
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: @color-blue;
        color: @color-white;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        border: 1px solid @color-blue;
        border-radius: 230px;
        cursor: pointer;
        overflow: hidden;
        transition: .3s ease;
        &::before {
          content: '';
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 100%;
          margin: -15px 0 0 1px;
          width: 35px;
          height: 30px;
          border-radius: 50%;
          background: @color-dark-blue;
          transform-origin: 100% 50%;
          transform: scale3d(1, 2, 1);
          transition: transform .3s, opacity .3s;
          transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
        }
        &:hover {
          border-color: @color-dark-blue;
        }
        &:not(.gray):hover::before {
          transform: scale3d(9, 9, 1);
        }
        &.inverted {
          background: @color-white;
          color: @color-blue;
          &::before {
            content: unset;
          }
          &:hover {
            color: @color-dark-blue;
            border-color: @color-dark-blue;
          }
          @media (max-width: 767px){
            width: 100%;
          }
        }
        &.gray {
          background: @color-light-gray;
          color: @color-blue;
          font-size: 20px;
          font-weight: 600;
          border-color: @color-light-gray;
        }

        span {
          position: relative;
          display: inline-block;
          z-index: 2;
        }
      }
    }

    .object-location {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      color: @color-black;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;

      svg {
        margin-right: 10px;
      }
    }

    .object-map {
      margin-bottom: 36px;
      border-radius: 16px;
      overflow: hidden;
    }

    .object-subheading {
      margin-bottom: 15px;
      color: @color-black;
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
    }

    .object-description {
      color: @color-black;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;

      p {
        margin: 0;
      }

      p:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
.type-icon-container {
  width: 80%;
  @media(max-width: 767px){
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
  }

  .type-icon {
    position: relative;
    padding-top: 100%;
    border-radius: 50%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}