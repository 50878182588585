.favorites-h1 {
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111111;
  margin-bottom: 0;
	@media(max-width: 991px){
		margin: 30px 0;	
	}
}
.page-header-block {
  margin-bottom: 40px;
  @media(max-width: 991px){
    margin: 0;
  }
}
.favorites {
  border-radius: 7px;
  background-color: #edf0f7;
  margin-bottom: 10px;
  padding: 22px 41px 22px 34px;
	@media(max-width: 575px){
		padding: 17px 22px;
	}
	@media(max-width: 991px){
		justify-content: space-between;
	}
	
  .like {
    text-align: right;

    button {
      background: transparent;
      outline: none;
      border: none;
			@media(max-width: 575px){
				padding: 0;	
			}
    }
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .circle-item {
    text-align: right;

    div {
      width: 36px;
      height: 36px;
      position: relative;
      opacity: 0.51;
      background-color: #5c7bb8;
      border-radius: 500px;

      span {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
				transform: translateY(-50%);
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

      }
    }
  }
  .date-item {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #575757;
  }
  .date-title {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #575757;
		@media(max-width: 575px){
			margin-bottom: 15px;
			line-height: 25px;	
		}
  }
	.kingItem {
		@media(max-width: 575px){
			margin-bottom: 15px;
			text-align: right;
			img {
				width: 23px;
			}
		}
	}
}