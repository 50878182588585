@gap: 25px;
.register {
  width: 100%;
  &-column {
    display: flex;
    flex-flow: column;
    gap: @gap;
  }
  &-row {
    h3 {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
      text-align: center;
    }

    .label {
      display: block;
      margin-bottom: 5px;
    }

    .require {
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8e8ea6;
      display: block;
      margin-top: 5px;
    }

    .reg-buttons {
      display: flex;
      align-items: center;
      gap: 35px;
      &.centered {
        justify-content: center;
      }
    }

    p {
      margin: 0 0 @gap;
      color: #7e7e7e;
      font-size: 16px;
      line-height: 1;
      text-align: center;
      &.bold {
        font-weight: 600;
      }
    }
  }


  .left {
    padding-right: 55px;
		@media(max-width: 575px) {
          padding-right: 0;
        }
  }
  .next {
    display: block;
  }
  .edited {
    .ant-radio-button-wrapper {
      border-radius: 7px;
      margin-right: 12px;
      margin-bottom: 12px;
      border: solid 1px #c7c7c7;
      color: #575757;
      @media(max-width: 575px){
        margin-right: 7px;
      }
      &:last-child {
        margin-right: 0;
      }
      &::before {
        display: none;
      }
    }
    .ant-radio-button-wrapper-checked {
      border-radius: 7px;
      border: solid 1px #1848a7;
      background-color: #1848a7;
      color: white;
    }
  }
  .ant-input-number {
    width: 220px;
    @media(max-width: 767px){
      width: 195px;
    }
    @media(max-width: 479px){
      width: 130px;
    }
  }
}
.singleCheckbox {
  margin: 30px 0;
  display: flex;
  align-items: center;
  @media(max-width: 479px) {
    margin: 20px 0;
    &:first-child {
      margin: 20px 0 0 0;
    }
  }
}

.create-item-block {
  width: 100%;
  margin-bottom: 18px;

  .item-label {
    margin-top: 10px;
    color: @color-black;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    &-big {
      margin: 18px 0;
      color: @color-black;
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
    }
  }

  .item-map {
    border-radius: 20px;
    overflow: hidden;
  }

  .ant-select {
    width: 100%;
    box-shadow: unset!important;

    .ant-select-selector {
      height: 36px;
      padding: 0 22px;
      border: 1px solid @color-gray!important;
      border-radius: 20px;
      box-shadow: unset!important;

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        color: @color-black;
        font-size: 14px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }

  .ant-input,
  .ant-input-number-input {
    height: 36px;
    padding: 0 22px;
    border: 1px solid @color-gray!important;
    border-radius: 20px;
    box-shadow: unset!important;
  }

  .ant-select-open {
    .ant-select-selection-item {
      color: transparent!important;
    }
  }

  .ant-upload,
  .ant-upload-list-item {
    .ant-btn {
      span:not(.anticon) {
        margin: 0;
        font-size: 0;
      }
      span.anticon {
        svg {
          margin: 0!important;
        }
      }
      &:hover {
        .anticon-delete svg path {
          fill: @main_color;
        }
      }
    }
  }

  .ant-input-number {
    border: unset!important;
  }

  .ql-snow {
    border: 1px solid @color-gray;
    &.ql-toolbar {
      border-radius: 20px 20px 0 0;
    }
    &.ql-container {
      border-radius: 0 0 20px 20px;
    }
  }

  .ant-radio-group {
    color: @color-black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    border: 1px solid @color-gray!important;
    border-radius: 20px;
    overflow: hidden;

    .ant-radio-button-wrapper {
      border: unset;
      border-right: 1px solid @color-gray!important;
      border-radius: 0;
      box-shadow: unset!important;

      &:last-child {
        border: unset!important;
      }

      &:hover {
        color: @color-black;
      }

      &.ant-radio-button-wrapper-checked {
        background: @color-light-gray;
        color: @color-black;
      }

      &::before {
        content: unset;
      }
    }
  }

  .singleCheckbox {
    margin: 10px 0 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
  }

  .ant-checkbox-inner {
    background: @color-white;
    border: 1px solid @color-gray!important;
    border-radius: 2px;
  }

  .ant-checkbox-checked{
    &::after {
      border-color: @color-gray!important;
    }

    .ant-checkbox-inner {
      background: @color-white;
      &::after {
        border-color: @color-blue;
      }
    }
  }

  .btn-animated {
    width: fit-content;
    margin-top: 40px;
    position: relative;
    border-radius: 230px;
    overflow: hidden;
    background: @color-blue;

    &::before {
      content: '';
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 100%;
      margin: -15px 0 0 1px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: @color-dark-blue;
      transform-origin: 100% 50%;
      transform: scale3d(1, 2, 1);
      transition: transform .3s, opacity .3s;
      transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
    }

    &:hover::before {
      transform: scale3d(9, 9, 1);
    }

    .ant-btn {
      height: unset;
      padding: 18px 26px!important;
      background: unset;
      text-shadow: unset;
      box-shadow: unset;

      &:hover {
        background: unset;
        color: @color-white;
      }

      span {
        position: relative;
        display: inline-block;
        color: @color-white;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        z-index: 2;
      }
    }
  }
}

.create-item-block {
  width: 100%;
  margin-bottom: 18px;

  .item-label {
    margin-top: 10px;
    color: @color-black;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    &-big {
      margin: 18px 0;
      color: @color-black;
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
    }
  }

  .input-row {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    & > * {
      flex: 1 0 0;
    }
    span {
      flex: 0 0 0;
      margin-right: 10px;
    }
    p {
      flex: 1 0 100%;
    }
  }

  .item-map {
    border-radius: 20px;
    overflow: hidden;
  }

  p.text {
    margin-top: 10px;
    margin-bottom: 0;
    color: #ccc;
    font-size: 12px;
    font-weight: 600;
    &.red {
      color: #b81313;
    }
  }

  .ant-select {
    width: 100%;
    box-shadow: unset!important;

    .ant-select-selector {
      height: 36px;
      padding: 0 22px;
      border: 1px solid @color-gray!important;
      border-radius: 20px;
      box-shadow: unset!important;

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        color: @color-black;
        font-size: 14px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }

  .ant-input,
  .ant-input-number-input {
    height: 36px;
    padding: 0 22px;
    border: 1px solid @color-gray!important;
    border-radius: 20px;
    box-shadow: unset!important;
  }

  textarea.ant-input {
    height: auto;
    padding: 7px 22px;
  }

  .ant-input-number {
    width: 100%;
    border: unset!important;
    box-shadow: unset!important;
    border-radius: 20px;
  }

  .ql-snow {
    border: 1px solid @color-gray;
    &.ql-toolbar {
      border-radius: 20px 20px 0 0;
    }
    &.ql-container {
      border-radius: 0 0 20px 20px;
    }
  }

  .ant-radio-group {
    color: @color-black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    border: 1px solid @color-gray!important;
    border-radius: 20px;
    overflow: hidden;

    .ant-radio-button-wrapper {
      border: unset;
      border-right: 1px solid @color-gray!important;
      border-radius: 0;
      box-shadow: unset!important;

      &:last-child {
        border: unset!important;
      }

      &:hover {
        color: @color-black;
      }

      &.ant-radio-button-wrapper-checked {
        background: @color-light-gray;
        color: @color-black;
      }

      &::before {
        content: unset;
      }
    }
  }

  .singleCheckbox {
    margin: 10px 0 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
  }

  .ant-checkbox-inner {
    background: @color-white;
    border: 1px solid @color-gray!important;
    border-radius: 2px;
  }

  .ant-checkbox-checked{
    &::after {
      border-color: @color-gray!important;
    }

    .ant-checkbox-inner {
      background: @color-white;
      &::after {
        border-color: @color-blue;
      }
    }
  }

  .btn-animated {
    width: fit-content;
    margin-top: 40px;
    position: relative;
    border-radius: 230px;
    overflow: hidden;
    background: @color-blue;

    &::before {
      content: '';
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 100%;
      margin: -15px 0 0 1px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: @color-dark-blue;
      transform-origin: 100% 50%;
      transform: scale3d(1, 2, 1);
      transition: transform .3s, opacity .3s;
      transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
    }

    &:hover::before {
      transform: scale3d(9, 9, 1);
    }

    .ant-btn {
      height: unset;
      padding: 18px 26px!important;
      background: unset;
      text-shadow: unset;
      box-shadow: unset;

      &:hover {
        background: unset;
        color: @color-white;
      }

      span {
        position: relative;
        display: inline-block;
        color: @color-white;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        z-index: 2;
      }
    }
  }
}