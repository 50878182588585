.news {

  h1 {
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #111111;
    margin-top: 28px;
    margin-bottom: 28px;
  }
  .linkAllNews {
    text-decoration: underline !important;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #111111;
  }
	&.single-news {
		.date {
			display: block;
		}
		*:not(:last-child) {
			margin-bottom: 30px;
		}
	}
}

.news-detail {
  cursor: pointer;
  &:hover {

  }

  .img {
    width: 100%;
    padding-top: 60%;
    background-size: cover;
    border-radius: 10px;
  }
  .content {
    margin-top: 15px;

    .date {
      font-size: 14px;
      line-height: 32px;
      color: #94A4B4;
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
    }
  }
}