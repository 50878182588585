.chatHead {
	margin-bottom: 30px;
	h1 {
		font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #111111;
		margin: 0;
	}
	@media(max-width: 991px){
		margin-top: 30px;
		.template-right {
			text-align: right;
		}
	}
}
.chat {
	position: relative;
  .left {
    padding-right: 14px;
		@media(max-width: 479px){
			padding: 0;
		}

    .in {
      background: #eceff3;
      border-radius: 7px;
      height: 612px;
      max-height: 612px;
      overflow-y: scroll;

      .in-in {
				& > * {
					margin: 0 0 7px;
				}
        .name {
					margin-top: 3px;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          color: #111111;
        }
        .text {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: #111111;
        }
        .date {
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #616161;
        }
        a {
					display: block;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1848a7;
        }
      }

      .people {
				display: flex;
        border-radius: 7px;
        padding: 12px 18px;
        height: auto;
				transition: .3s ease;

        &:hover {
					background-color: #d0d8e9;
          cursor: pointer;
        }

				& > span:first-child {
                  flex-shrink: 0;
					margin-right: 15px;
				}

        &.active {
          background-color: #d0d8e9;
        }


        .in {
          background: transparent;
        }
      }
    }
  }
  .right {
    background: #eceff3;
    border-radius: 7px;
    height: 612px;
    position: relative;

		@media(max-width: 479px){
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(120%);
			width: 100%;
			padding-top: 50px;
			opacity: 0;
			transition: .5s ease;
			z-index: 2;

			&.shown {
				transform: translateX(0);
				opacity: 1;
			}
			#backToChats {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				padding: 8px 15px;
				background: #1848a7;
				font-size: 16px;
				color: #fff;
				border-radius: 7px 7px 0 0;

			}
		}
		@media(min-width: 480px){
			#backToChats {
				display: none;
			}
		}

    .txt {
      height: 100%;
      max-height: 480px;
      overflow-y: auto;
    }
    .textarea {
      position: absolute;
      bottom: 14px;
      left: 0;
      right: 0;
      width: 98%;
      margin: 0 auto;
    }
    .enter {
      position: absolute;
      bottom: 24px;
      right: 24px;
      font-size: 12px;
      font-weight: 500;
      color: #808080;
			pointer-events: none;
    }

    .chat-position-my {
      display: block;
      text-align: right;
      padding: 7px 14px;
    }
    .chat-position {
      display: block;
      padding: 7px 14px 14px;
      @media(max-width: 767px){
        padding: 7px 14px 18px;
      }

      & > * {
        min-width: 100px;
      }
    }
    .chat_dialog {
      padding: 9px 16px 9px 11px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      display: inline-block;
      position: relative;

    }
		.time-message {
			position: absolute;
			top: 21px;
			color: #535353;
			transform: translateY(-50%);
		}
    .chat_dialog_message_my {
      background-color: #1848a7;
      text-align: right;
      color: white;
      border-radius: 7px 7px 0 7px;

      .time-message {
        left: -35px;
      }
			@media(max-width: 767px){
				margin-left: 40px;
			}
    }
    .chat_dialog_message {
      background-color: #c8d1dc;
      color: #111111;
      border-radius: 7px 7px 7px 0;

      .time-message {
        right: -35px;
      }
      .like-message{
        position: absolute;
        right: -10px;
        bottom: -15px;

        & > button {
          //opacity: 0;
          //visibility: hidden;
          transition: .3s ease;
        }
        & > button.ant-dropdown-open {
          opacity: 1;
          visibility: visible;
        }
        @media(max-width: 991px){
          opacity: 1;
          visibility: visible;
        }

        & > *:first-child {
          margin-right: 5px;
        }
      }
      &:hover {
        .like-message button {
          opacity: 1;
          visibility: visible;
        }
      }
			@media(max-width: 767px){
				margin-right: 40px;
			}
    }
    .text-message {
      max-width: 352px;
			margin: 0;
    }
  }
}